<template>
    <div>
        <img class="example-headImg" src="../../assets/image/selected/03-1-1banner.png"/>
        <div class="head-explainBox">
            <div class="head-explainBox-title1">布局原料赋码，助力品质生产</div>
            <div class="head-explainBox-content1">原料流转过程管控与质量追溯</div>
            <div class="head-explainBox-line"></div>
            <div class="head-explainBox-title2">项目介绍</div>
            <div class="head-explainBox-content2">近年来，零售快消行业品牌竞争激烈，如何在激烈的市场竞争中保持稳健的增长，同时保障产品品质及企业
                生产效力的提高是企业一直探索前进的方向。该企业对原料（尤其主剂、农产品）的存储环境、有效期管理更是要求严格，然而企业物料管理细分场景
                增多，产品需求呈现小批次、多品类，进一步增加了企业仓储管理的难度。针对该企业现状提出智能仓储，进行原料流转过程管控与质量追溯管理，实
                现企业上下游信息无缝衔接，减少非必要作业流转的沟通，建立生产力报告数据基础，布局原料赋码，助力品质生产。</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem" @click="goAssignBlock('block'+ 0,50)"><span class="title">业务痛点</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 1,50)"><span class="title">实施方案</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 2,50)"><span class="title">实施收益</span></div>
        </div>
        <div class="container-1" style="display:flex" ref="block0">
            <div class="purchase-content-left">
                <div class="purchase-title">业务痛点</div>
                <div class="purchase-content" style="width:24.8rem">该企业在精细化管理原料到生产过程时，仍面临以下挑战</div>
            </div>
            <div class="purchase-content-right">
                <div class="selected-box">
                    <div class="selected-card">
                        <div class="title1">多SKU、多环节流转，<br/>物料管理繁杂</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">   纸质记录信息不实时，<br/>
                            数据未得到有效利用</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">各环节作业过程不透明，<br/>
                            质量问题溯源困难</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 190rem;" ref="block1">
            <div class="purchase-content-left">
                <div class="purchase-title">实施方案</div>
                <div class="purchase-content" style="width:24.8rem">以“简洁、高效、防呆”为原则，比孚智能仓储采用“统一赋码管理+移动作业”模式，
                    通过智能PDA扫码硬件实时采集物料信息并流转作业，结合智能仓储系统与采购、生产、财务协同，实现原料入库到生产使用全流程管理。智能仓储提供原料便捷入库报检，
                    发料按需创建及自动下发，生产投退料管理，以及库存调拨、盘点管理、原料成品溯源等功能，真正实现了仓储到生产的智能化、数字化、快速反应、高效可追溯管理。</div>
            </div>
            <div class="purchase-content-right">
                <img class="solution-img" src="../../assets/image/selected/03-1-1解决方案1.svg"/>
                <div class="purchase-title" style="margin-top:4.7rem">原料入库管理</div>
                <div class="purchase-content">原料一托一码，批次管理，PDA便捷收货，自动报检，快速入库；</div>
                <div class="purchase-title">发料出库管理</div>
                <div class="purchase-content">支持多种模式发料需求创建，任务自动下发PDA，仓库按需拣货发料；</div>
                <div class="purchase-title">生产投料管理</div>
                <div class="purchase-content">CIP关联订单，绑定投料口物料；调配缸次投料单，PDA扫描投料口自动带出对应物料；</div>
                <div class="purchase-title">数据报表查询</div>
                <div class="purchase-content">物料流转数据自动统计，包含收发存明细汇总，实时库存，投料明细等；</div>
                <div class="purchase-title">可视化数字看板</div>
                <div class="purchase-content">实时监控果汁果肉解冻时长、物料保质到期情况，自动预警。</div>
                <img class="solution-img2" src="../../assets/image/selected/03-1-1解决方案2.svg"/>
            </div>
        </div>
        <div class="container-1" style="display:flex;margin-bottom:10rem" ref="block2">
            <div class="purchase-content-left">
                <div class="purchase-title">实施收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-title">提高物料管理准确性，实现系统账实相符</div>
                <div class="purchase-content">通过物资托盘化标签管理，PDA扫描数据采集进行实物管理，全流程线上化操作，减少因错误作业或信息不流通所引起的信息不符。</div>
                <div class="purchase-title">降低企业仓储管理运营成本</div>
                <div class="purchase-content">通过智能仓储线上化管理，提高仓库信息化程度、整体仓储物流管理水平，仓储人员素质，并且形成标准作业规范，减低人员流动成本，降低因信息流<br/>
                    和作业流无法统一致使总体仓储成本提高。</div>
                <div class="purchase-title">提高生产力效率</div>
                <div class="purchase-content">基于条码进行精确的库位管理和PDA扫描辅助存储与拣选工具，提升现场作业效率与准确率，从而减少原料从收货到投料时间，提高生产力效率。</div>
                <div class="purchase-title">高效管控，便捷溯源</div>
                <div class="purchase-content">系统全流程跟踪原料到成品过程，双向溯源，精确定位问题源头，一键查找，便捷追溯某个环节产生问题批次的产品。</div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'智能仓储,布局原料赋码,原料流转过程管控与质量追溯,原料入库管理,发料出库管理,生产投料管理,数据报表查询,可视化数字看板' },
        { name:'description', content:'近年来，零售快消行业品牌竞争激烈，如何在激烈的市场竞争中保持稳健的增长，同时保障产品品质及企业 生产效力的提高是企业一直探索前进的方向。该企业对原料（尤其主剂、农产品）的存储环境、有效期管理更是要求严格，然而企业物料管理细分场景 增多，产品需求呈现小批次、多品类，进一步增加了企业仓储管理的难度。针对该企业现状提出智能仓储，进行原料流转过程管控与质量追溯管理，实 现企业上下游信息无缝衔接，减少非必要作业流转的沟通，建立生产力报告数据基础，布局原料赋码，助力品质生产。' }
        ]
    },
    data() {
        return {
            navOffsetTop: 0,
        };
    },

    mounted() {
        this.activeIndex = '3'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
        // 监听滚动事件
        window.addEventListener('scroll', this.fiexdNav)
        this.getData()
    },
    destroyed () {
        // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
        window.removeEventListener('scroll', this.fiexdNav)
    },
    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        goAssignBlock(el, speed) {
        const navHeight = document.querySelector('.head-list').offsetHeight
        let _this = this;
        let windowH = window.innerHeight; //浏览器窗口高度
        let h = this.$refs[el].offsetHeight; //模块内容高度
        let t = this.$refs[el].offsetTop; //模块相对于内容顶部的距离
        let top = t; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
        let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop; //滚动条距离顶部高度
        let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
        let requestId;
        //采用requestAnimationFrame，平滑动画
        function step() {
            //判断让滚动条向上滚还是向下滚
            if (scrollTop < top) {
            if (currentTop <= top) {
                //   window.scrollTo(x,y) y为上下滚动位置
                window.scrollTo(0, currentTop - navHeight - 40);
                requestId = window.requestAnimationFrame(step);
            } else {
                window.cancelAnimationFrame(requestId);
            }
            //向下滚动
            currentTop += speed;
            } else {
            if (top <= currentTop) {
                //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
                window.scrollTo(0, currentTop - speed - navHeight);
                requestId = window.requestAnimationFrame(step);
            } else {
                window.cancelAnimationFrame(requestId);
            }
            //向上滚动
            currentTop -= speed;
            }
        }
        window.requestAnimationFrame(step);
        },
        /** 设置导航条nav到达页面顶部时固定 **/
        // 1.获取导航条nav的offsetTop值，存储在data中（注：之所以不放在滚动事件中，是为了以防添加固定样式后offsetTop值为零,导致页面需要滚动到最上面才可以回到原位）
        getData () {
            this.navOffsetTop = document.querySelector('.head-list').offsetTop + 60
            console.log('this.navOffsetTop',this.navOffsetTop)
        },
        fiexdNav () {
            // 2.获取当前页面的卷曲高度
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            const nav = document.querySelector('.head-list')
            // 3.判断卷曲高度是否大于等于导航条的offsetTop值
            if (scrollTop > this.navOffsetTop) {
                //   3.1若满足，则给nav导航添加固定样式
                nav.classList.add('fixedNav')
            } else {
                //   3.2若不满足，则删除nav导航的固定样式
                nav.classList.remove('fixedNav')
            }

            /** 当滚动到一定区域时给导航项添加选中样式 **/
            //  1.获取所有锚点元素
            // 2.获取锚点元素的offsetTop值，并收集在一个数组
            const contentsOffsetTop = []
            // contents.forEach(item => {
            //     contentsOffsetTop.push(item.offsetTop)
            //     console.log('2222',item.offsetTop)
            // })
            contentsOffsetTop.push(this.$refs['block0'].offsetTop)
            contentsOffsetTop.push(this.$refs['block1'].offsetTop)
            contentsOffsetTop.push(this.$refs['block2'].offsetTop)
            console.log('contentsOffsetTop',contentsOffsetTop)
            // 3.获取页面高度
            const pageHeight = window.innerHeight
            // 4.获取nav的子元素
            const navChildren = document.querySelectorAll('.head-list .head-list-item')
            for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[0].classList.add('head-list-item-ischeck')
                }
            if(scrollTop>500){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[1].classList.add('head-list-item-ischeck')
                }
            }
            if(scrollTop>2000){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[2].classList.add('head-list-item-ischeck')
                }
            }
            // 5.遍历锚点元素的offsetTop值
            // for (let i = 0; i < contentsOffsetTop.length; i++) {
            //     // 5.1 设置第一项导航默认为选中状态
            //     if (i === 0) {
            //     navChildren[0].classList.add('head-list-item-ischeck')
            //     } else if (scrollTop > contentsOffsetTop[i - 1]) {
            //     // 排他思想
            //     for (let j = 0; j < contentsOffsetTop.length; j++) {
            //         navChildren[j].classList.remove('head-list-item-ischeck')
            //         navChildren[i].classList.add('head-list-item-ischeck')
            //     }
            //     } else {
            //     navChildren[i].classList.remove('head-list-item-ischeck')
            //     }
            // }
        },
        /**
         *设置点击导航跳转到指定选择器对应的锚点元素
        * @param {*} selector
        **/
        skipTo (selector) {
            const navHeight = document.querySelector('.head-list-item').offsetHeight
            // scrollIntoView() js原生方法，实现锚点滚动过渡
            const target = document.querySelector(selector)
            target.scrollIntoView({ behavior: 'smooth' })
            // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
            window.scrollTo(0, target.offsetTop - navHeight)
        }
    },
};
</script>

<style lang="less" scoped>
.purchase-content-left{
    width:33rem;
    text-align: justify;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: justify;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 106.8rem;
    height: 71.7rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    height: 15rem;
    display: flex;
    .selected-card{
        width: 35.6rem;
        height: 15rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        .title1{
            text-align: center;
            width: 29.2rem;
            height: 6rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #DF2C26;
    }
}
.purchase-box{
    width: 106rem;
    height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    /* flex-wrap: wrap; */
    justify-content: space-between;
    
    .card{
        width: 25.6rem;
        height: 26.5rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
        justify-content: center;
        .logo{
            margin: 3rem 0 0 9.6rem;
        }
        .up-down{
            margin-top: 1rem;
            margin-left: 0.4rem;
            width: 1.6rem;
            height: 1.6rem;
        }
        .title1{
            width: 6rem;
            height: 2rem;
            font-size: 2rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;
            display: flex;

            margin: auto;
            margin-top: 2.2rem;
        }
        .title2{
            width: 6.1rem;
            height: 3rem;
            font-size: 3rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #11A84F;
            line-height: 4.5rem;

            margin: auto;
            margin-top: 2.8rem;
        }
        .title3{
            // width: 9.6rem;
            text-align: center;
            height: 1.6rem;
            font-size: 1.6rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;
            margin: auto;
            margin-top: 0.7rem;
        }
    }
}
</style>
