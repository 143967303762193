var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"example-headImg",attrs:{"src":require("../../assets/image/selected/03-1-1banner.png")}}),_vm._m(0),_c('div',{staticClass:"head-list"},[_c('div',{staticClass:"head-list-item head-list-item-ischeck",staticStyle:{"margin-left":"26.3rem"},on:{"click":function($event){return _vm.goAssignBlock('block'+ 0,50)}}},[_c('span',{staticClass:"title"},[_vm._v("业务痛点")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 1,50)}}},[_c('span',{staticClass:"title"},[_vm._v("实施方案")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 2,50)}}},[_c('span',{staticClass:"title"},[_vm._v("实施收益")])])]),_c('div',{ref:"block0",staticClass:"container-1",staticStyle:{"display":"flex"}},[_vm._m(1),_vm._m(2)]),_c('div',{ref:"block1",staticClass:"container-2",staticStyle:{"display":"flex","height":"190rem"}},[_vm._m(3),_vm._m(4)]),_c('div',{ref:"block2",staticClass:"container-1",staticStyle:{"display":"flex","margin-bottom":"10rem"}},[_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-explainBox"},[_c('div',{staticClass:"head-explainBox-title1"},[_vm._v("布局原料赋码，助力品质生产")]),_c('div',{staticClass:"head-explainBox-content1"},[_vm._v("原料流转过程管控与质量追溯")]),_c('div',{staticClass:"head-explainBox-line"}),_c('div',{staticClass:"head-explainBox-title2"},[_vm._v("项目介绍")]),_c('div',{staticClass:"head-explainBox-content2"},[_vm._v("近年来，零售快消行业品牌竞争激烈，如何在激烈的市场竞争中保持稳健的增长，同时保障产品品质及企业 生产效力的提高是企业一直探索前进的方向。该企业对原料（尤其主剂、农产品）的存储环境、有效期管理更是要求严格，然而企业物料管理细分场景 增多，产品需求呈现小批次、多品类，进一步增加了企业仓储管理的难度。针对该企业现状提出智能仓储，进行原料流转过程管控与质量追溯管理，实 现企业上下游信息无缝衔接，减少非必要作业流转的沟通，建立生产力报告数据基础，布局原料赋码，助力品质生产。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("业务痛点")]),_c('div',{staticClass:"purchase-content",staticStyle:{"width":"24.8rem"}},[_vm._v("该企业在精细化管理原料到生产过程时，仍面临以下挑战")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"selected-box"},[_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("多SKU、多环节流转，"),_c('br'),_vm._v("物料管理繁杂")])]),_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v(" 纸质记录信息不实时，"),_c('br'),_vm._v(" 数据未得到有效利用")])]),_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v("各环节作业过程不透明，"),_c('br'),_vm._v(" 质量问题溯源困难")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("实施方案")]),_c('div',{staticClass:"purchase-content",staticStyle:{"width":"24.8rem"}},[_vm._v("以“简洁、高效、防呆”为原则，比孚智能仓储采用“统一赋码管理+移动作业”模式， 通过智能PDA扫码硬件实时采集物料信息并流转作业，结合智能仓储系统与采购、生产、财务协同，实现原料入库到生产使用全流程管理。智能仓储提供原料便捷入库报检， 发料按需创建及自动下发，生产投退料管理，以及库存调拨、盘点管理、原料成品溯源等功能，真正实现了仓储到生产的智能化、数字化、快速反应、高效可追溯管理。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('img',{staticClass:"solution-img",attrs:{"src":require("../../assets/image/selected/03-1-1解决方案1.svg")}}),_c('div',{staticClass:"purchase-title",staticStyle:{"margin-top":"4.7rem"}},[_vm._v("原料入库管理")]),_c('div',{staticClass:"purchase-content"},[_vm._v("原料一托一码，批次管理，PDA便捷收货，自动报检，快速入库；")]),_c('div',{staticClass:"purchase-title"},[_vm._v("发料出库管理")]),_c('div',{staticClass:"purchase-content"},[_vm._v("支持多种模式发料需求创建，任务自动下发PDA，仓库按需拣货发料；")]),_c('div',{staticClass:"purchase-title"},[_vm._v("生产投料管理")]),_c('div',{staticClass:"purchase-content"},[_vm._v("CIP关联订单，绑定投料口物料；调配缸次投料单，PDA扫描投料口自动带出对应物料；")]),_c('div',{staticClass:"purchase-title"},[_vm._v("数据报表查询")]),_c('div',{staticClass:"purchase-content"},[_vm._v("物料流转数据自动统计，包含收发存明细汇总，实时库存，投料明细等；")]),_c('div',{staticClass:"purchase-title"},[_vm._v("可视化数字看板")]),_c('div',{staticClass:"purchase-content"},[_vm._v("实时监控果汁果肉解冻时长、物料保质到期情况，自动预警。")]),_c('img',{staticClass:"solution-img2",attrs:{"src":require("../../assets/image/selected/03-1-1解决方案2.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("实施收益")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-title"},[_vm._v("提高物料管理准确性，实现系统账实相符")]),_c('div',{staticClass:"purchase-content"},[_vm._v("通过物资托盘化标签管理，PDA扫描数据采集进行实物管理，全流程线上化操作，减少因错误作业或信息不流通所引起的信息不符。")]),_c('div',{staticClass:"purchase-title"},[_vm._v("降低企业仓储管理运营成本")]),_c('div',{staticClass:"purchase-content"},[_vm._v("通过智能仓储线上化管理，提高仓库信息化程度、整体仓储物流管理水平，仓储人员素质，并且形成标准作业规范，减低人员流动成本，降低因信息流"),_c('br'),_vm._v(" 和作业流无法统一致使总体仓储成本提高。")]),_c('div',{staticClass:"purchase-title"},[_vm._v("提高生产力效率")]),_c('div',{staticClass:"purchase-content"},[_vm._v("基于条码进行精确的库位管理和PDA扫描辅助存储与拣选工具，提升现场作业效率与准确率，从而减少原料从收货到投料时间，提高生产力效率。")]),_c('div',{staticClass:"purchase-title"},[_vm._v("高效管控，便捷溯源")]),_c('div',{staticClass:"purchase-content"},[_vm._v("系统全流程跟踪原料到成品过程，双向溯源，精确定位问题源头，一键查找，便捷追溯某个环节产生问题批次的产品。")])])
}]

export { render, staticRenderFns }